<template>

  <div class="vehicles plan_item">

    <div class="expanded py-4">
      <div class="actions flex flex-col text-center items-center gap-10p">
        <div class="white-green w-full flex items-center justify-around pointer"
             @click="addVehicles"
        >
          <div class="flex text-center items-center gap-10p w-5/12">
            <div class="icon">
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M3.5 18H2C1.44772 18 1 17.5523 1 17V4C1 3.44772 1.44772 3 2 3H13C13.5523 3 14 3.44772 14 4V18H7.5" stroke="#1FBC2F" stroke-width="2"/>
                <circle cx="5.5" cy="19.5" r="2.5" stroke="#1FBC2F" stroke-width="2"/>
                <circle cx="18.5" cy="19.5" r="2.5" stroke="#1FBC2F" stroke-width="2"/>
                <path d="M20.5 18H22C22.5523 18 23 17.5523 23 17V11.2361C23 11.0808 22.9639 10.9277 22.8944 10.7889L20.2764 5.55279C20.107 5.214 19.7607 5 19.382 5H15C14.4477 5 14 5.44772 14 6V13.6667V18H16.5" stroke="#1FBC2F" stroke-width="2"/>
                <path d="M23 11H14" stroke="#1FBC2F" stroke-width="2"/>
              </svg>
            </div>
            <span>
                      Add vehicle
                    </span>
          </div>
        </div>
      </div>
      <div class="sliders flex flex-col py-4">
        <div class="item flex flex-row w-full"
             v-for="(item, index) in vehiclesDimensions"
             :key="index"
        >
          <div class="name w-1/3 flex flex-row">
            <span>{{ item.name }}&nbsp;</span>
            <span>(&nbsp;{{item.val}}&nbsp;</span>
            <span>{{item.dimention}}&nbsp;)</span>
          </div>
          <VueRangeSlider
              class="w-2/3 "
              :min="item.min"
              :max="item.max"
              :step="item.step"
              :process-style="{backgroundColor: '#1fbc2f'}"
              :tooltip="null"
              v-model="item.val"
              @drag-end=changeVehicleDimensions()
          ></VueRangeSlider>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import VueRangeSlider from "vue-range-component";

export default {
  name: 'PlanVehicles',
  components: {
    VueRangeSlider
  },
  props: {
    close_modal: {
      type: Boolean,
      default: false
    },
    vehiclesDimensions: {
      type: Object,
      default: () => {
        return {};
      }
    },
    reset_all: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      selectedVehicles: [],
    }
  },
  methods: {
    addVehicles() {
      this.$emit('selectVehicles')
    },
    changeVehicleDimensions() {
      this.$emit('changedVehicleDimensions')
    }
  },
  mounted() {
    VueRangeSlider.methods.handleKeyup = ()=> console.log;
    VueRangeSlider.methods.handleKeydown = ()=> console.log;
  },
  watch: {
    reset_all() {
      this.selectedVehicles = [];
    }
  }
}
</script>

<style lang="scss" scoped>
.vue2leaflet-map {
  height: 877px;
}
.location-name {
  font-weight: bold;
  font-size: 14px;
  color: #292929;
}
.add_items {
  position: absolute;
  top: 180px;
  left: 400px;
  min-height: 150px;
  z-index: 400;
  background-color: #fff;
  border-radius: 5px;
  &.r-side{
    left: calc(35% + 400px);
  }
}
.not-allow-banner {
  padding-top: 150px;
  margin-bottom: 200px;
}
.page {
  position: relative;
  .white-green {
    cursor: pointer;
  }
  .drivers {
    .white-green {
      padding: 12px;
    }
  }
  .inner {
    &.way {
      max-height: 650px;
      max-width: 350px;
      min-width: 320px;
      overflow-y: auto;
    }
  }
  .vue-range-slider.slider-component {
    .slider {
      .slider-dot {
        background-color: #1fbc2f !important;
      }
    }
  }
  .vehicles {
    .actions {
      .white-green {
        text-align: center;
        margin: auto;
      }
    }
  }
  .vue-range-slider {
    width: 100%!important;
  }
  .alt_header {
    position: absolute;
    height: 50px;
    z-index: 401;
  }
  .outer {
    position: relative;
    .input_actions {
      position: absolute;
      right: 0;
      top: 10px;
      width: 60px;
    }
  }
  .plan_options {
    min-width: 355px;
    position: absolute;
    left: 15px;
    z-index: 401;
    border-radius: 6px;
    background-color: #ffffff;
    .actions {
      .white-green {
        padding-top: 0;
        padding-bottom: 0;
        min-height: 44px;
      }
    }
    &>.inner {
      padding: 15px;
      max-height: 600px;
      overflow: scroll;
    }
  }
  .position_plan_options {
    top: 90px;
  }
  .position_plan_options_plus {
    top: 90px;
  }
}
.btn_danger {
  padding: 7px 10px;
}
.input-group {
  input {
    border: 1px solid #D0D5DA;
    box-sizing: border-box;
    border-radius: 18px;
  }
}
.plan_item {
  .expand {
    padding-right: 10px;
    .active {
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .mb30 {
    .flex {
      width: 100%;
      .mr-2 {
        margin: 0;
        width: 100%;
      }
      .vue-daterange-picker {
        width: 100%;
      }
    }
  }
}
.actions {
  &.save {
    padding-top: 15px;
    button {
      min-width: 80px;
    }
  }
}
.preferences {
  input[type="radio"] {
    max-width: 20px;
    &:checked {
      &:checked {
        background: #FFA800 !important;
        border: #FFA800 !important;
      }
      &:before, &:after {
        background: #FFA800 !important;
        border: #FFA800 !important;
      }
    }
  }
}
.vue-daterange-picker ::v-deep .reportrange-text{
  border: 1px solid #D0D5DA;
  box-sizing: border-box;
  border-radius: 18px !important;
  width: 100%;
}
.data {
  padding-bottom: 20px;
  .icon {
    width: 20px;
  }
}
.icon {
  &.rotated {
    width: 44px;
    height: 44px;
    background: #fff;
    border-radius: 8px;
    left: 15px;
    position: absolute;
    top: 150px;
    z-index: 999;
  }
}
.add_name {
  position: absolute;
  max-width: 400px;
  left: 0;
  right: 0;
  top: 300px;
  margin: auto;
  z-index: 401;
  background: #fff;
}
.closeZone {
  transform: rotate(180deg);
  float: left;
}
.openZone {
  background: #fff;
  padding: 5px 5px;
  border-radius: 4px;
  width: 34px;
  border: 2px solid rgba(0,0,0,.2);
  background-clip: padding-box;
}
.custom-control {
  .controls {
    width: 230px;
    height: 150px;
    padding-left: 15px;
    background-color: #fff;
    img {
      max-width: 20px;
    }
  }
}
.no_visibility {
  opacity: 0;
}
.dropdown_block {
  width: 100%;
  padding: 0 10px;
  z-index: 405;
  background: #ffffff;
  border: 1px solid #D0D5DA;
  box-sizing: border-box;
  border-radius: 5px;
  position: absolute;
  top: 54px;
  left: 0;
}
.title {
  font-size: 14px;
}
.locations{
  &.absolute {
    background-color: #fff;
    width: 100%;
    z-index: 100;
  }
}
.down {
  .rotate {

  }
}
</style>